/*****************************************************/
// MENU
/* needs main.js to toggle var menu = ".menu-mobile"; */
$size-nav-upperpart : 100px;
$size-nav-lowerpart : 30px;
$size-bar-height-etages : $size-nav-upperpart + $size-nav-lowerpart;

nav.module-menu {
    position: relative;
    ul {
        list-style: none;
    }

    /****************************************************
    /* Set if the menu is over the slide show or not
    */

    //padding-bottom: $size-nav-height;// if menu NOT on top of slideshow OR
    padding-bottom: 0;// if menu ON TOP of slideshow

    //@media screen and (max-width: $size-nav-toggle-breakpoint) {
    //    padding-bottom: $size-nav-height-mobile; //no padding on mobile
    //}
    /****************************************************/

    li {
        line-height: 1.5;
    }


    /************ menu navbar ***************************/
    .menu-navbar {
        position: fixed;
        top: 0;
        z-index: $z-index-menu;
        width: 100%;
        // height: $size-nav-upperpart + $size-nav-lowerpart;
        @extend .bg-color-nav; // see _colors.scss

        &.nav-invisible {
            top: -85px;
        }
        transition: all $transition-speed-fast ease;

        .upperpart.remplissage {
            background: url("../images/_header_bg.jpg") no-repeat;
            background-size: cover;
        }
        
        .upperpart {
            height: 85px;
            .wrapper.left {
                width: 35%;
                .logo {
                    align-items: center;
                    padding-left: 6%;
                }
            }    
            // @extend .pt4;
            @extend .flex;
            @extend .justify-between;
            @extend .items-center;
            .wrapper.right {
                ul {
                  @extend .flex;
                  @extend .justify-between;
                  @extend .items-center;
                    li {
                    }
                    li.phone {
                        a {
                            height: 100%;
                            @extend .flex;
                            @extend .items-center;
                            @extend .justify-end;
                            @extend .pr2;

                            img {
                                &:nth-child(1) {
                                    opacity: 1;
                                }
                                &:nth-child(2) {
                                    position: absolute;
                                    opacity: 0;
                                }
                            }

                            span.ville {
                                @extend .pr2;
                            }
                            span.number {
                                @extend .pr2;
                                @extend .font-2-bold;
                                font-size: 28px;
                                font-weight: 700;
                                color: $color-white;
                            }
                            div {
                                @extend .flex;
                                @extend .justify-center;
                                min-width: 16px;
                            }
                        }
                        a .hoverable {
                            @extend .filter-normal;
                            transition: .3s;
                        }
                        a:hover {
                          span.number {
                              color: #142954;
                              transition: .3s;
                          }
                          img {
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                                &:nth-child(2) {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        display: none;
                    }
                }

            }
        }
        .lowerpart {
            height: 50px;
            background-color: #142954;
            .main-menu {
                @media screen and (max-width: $size-nav-toggle-breakpoint) {
                    display: none;
                }
                margin: 0;
                height: 100%;
                align-items: center;
                li.item {
                  padding: 0 35px;
                  height: 100%;
                  transition: .3s;
                    a {
                        height: 100%;
                        div{
                            color: $color-white;
                            white-space: nowrap;
                            font-size: $font-size-22;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:hover {
                                color: #d99529;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
            .main-menu.right {
                display: flex;
                justify-content: right;
                align-items: center;
                position: absolute;
                right: 0;
                padding-right: 1.5rem; 
                ul {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    li.lang {
                        margin-right: 25px;
                        div {
                            color: $color-white;
                            font-size: $font-size-22;
                            &:hover {
                                color: #d99529;
                            }
                        }
                    }
                    a {
                        height: 100%;
                        img {
                            &:nth-child(1) {
                                opacity: 1;
                            }
                            &:nth-child(2) {
                                position: absolute;
                                opacity: 0;
                            }
                        }
                    }
                    a:hover {
                        img {
                            &:nth-child(1) {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                opacity: 1;
                                right: 30px;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: $size-lg-min) {
                .main-menu {
                    li.item {
                        a div{
                            transition: .3s;
                            font-size: $font-size-18;
                        }
                    }
                }
            }
        }
    }

/************ menu toggle ***************************/
    .menu-toggle {
        display: none;
        @media screen and (max-width: $size-nav-toggle-breakpoint) {
            display: block;
        }
        cursor: pointer;
        @extend .pr3;

        .bar-top, .bar-bottom {
            // box-shadow: 0px 0px 4px $color-black;
            width: 100%;
            height: $size-nav-toggle-bar-height;
            display: block;
            @extend .bg-color-nav-toggle;
        }

        .bar-top {
             margin-bottom: 3px;
        }

        .bar-bottom {
             margin-top: 1px;
        }

        .word {
            // text-shadow: 0px 0px 6px white;
            @extend .font-1-normal;
            @extend .text-color-nav-toggle;
        }
    }

    /************ menu mobile ***************************/
    #menu-mobile {
        width: 100%;
        top: 0;
        right: -100%;
        z-index: $z-index-menu-mobile;
        // overflow: hidden;
        position: fixed;
        @extend .bg-color-nav-toggle-backdrop;
        transition: all $transition-speed-fast ease-in;

        &.toggled {
            overflow-y: auto;
            bottom: 0; // pour avoir le scroll
            transform: translate(-100%, 0%);
        }

        .menu-toggle {
            float: right;
            margin: 15px 0 0 0;

            .word {
                @extend .text-color-nav;
            }
        }

        ul {
            margin: 25px auto;
            padding: 30px;

            li {
                // position: relative;
                text-align: center;
                line-height: 100%;
                padding: 15px;
                border-bottom: 1px solid #fff;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                &.top-bar {
                    display: flex;
                    @extend .flex-wrap;
                    @extend .justify-between;
                    @extend .items-center;

                    div.icons {
                        flex: 1 1;

                        & > a {
                            img {
                                margin: 5px;
                            }
                        }
                    }

                    a {
                        flex: 1 1;
                    }
                }

                & > a, & > .menu-title {
                    @extend .font-nav-items;
                    @extend .text-color-nav;
                }

                .submenu {
                    margin: 0px auto;
                    padding: 10px 0 10px;

                    ul {
                        margin: 0;
                        padding: 0;

                        li.subitem {
                            border-bottom: none;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            .menu-title {
                                margin-right: 20px;
                                font-weight: 700;

                            }
                            ul {
                                border-left: 1px $line-color-nav-mobile solid;
                                li {
                                    padding: 5px 20px;
                                }
                            }
                            a, a div {
                                @extend .font-nav-drop-items;
                                @extend .text-color-nav;
                                font-weight: 300;
                            }

                            &:hover {
                                > a, > a div {
                                    @extend .text-color-nav-hover;
                                }
                            }

                            &.active {
                                a, a div {
                                    @extend .text-color-nav-active;
                                }
                            }
                        }
                    }

                }

                &:hover {
                    > a, > a div {
                        @extend .text-color-nav-hover;
                    }
                }

                &.active, &.phone, &.lang {
                    > a, > a div {
                        color: #142954;
                    }
                }
            }
        }
    }
}
