// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
//@import 'modules/menu';
// @import 'menu-centre';
@import 'modules/menu-etage';

/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-title-img {
    display: flex;
    padding-left: 2%;
    .left-side {
        width: 60%;
        padding-top: 6%;
        h2 {
            font-weight: bold;
            font-size: $font-size-80;
            color: #142954;
        }
    }
}

#section-rbq {
    display: flex;
    justify-content: right;
    padding: 20px 2%;
    h4 {
        font-weight: normal;
        font-size: $font-size-32;
        color: #142954;
        text-transform: uppercase;
    }
}

#section-img-text {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 2% 15px 2%;
    .bloc {
        width: 50%;
    }
    .bloc.text {
        padding: 15px 50px;
        p {
            font-weight: normal;
            font-size: $font-size-34;
            color: #142954;
            margin: 0;
            line-height: 1.5;
            font-family: $font-family-2;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-48;
            color: #142954;
            padding-bottom: 10px;
        }
    }
}

#section-logo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 2%;
    .logo {
        width: 15%;
        margin: auto;
        .img-responsive {
            margin: auto;
        }
    }
}

#background {
    background: url("../images/accueil_section06_pho01.jpg") no-repeat;
    background-size: 100%;
    min-height: 329px;
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

main {
    margin-top: 135px;
}

#section-title {
    padding: 35px 2%;
    h2 {
        font-weight: 500;
        font-size: $font-size-46;
        color: #5a7bb1;
    }
}

#section-images-text {
    padding: 0 2%;
    .bloc {
        display: flex;
        .left-side {
            width: 50%;
            h3 {
                font-weight: bold;
                font-size: $font-size-46;
                color: #142954;
                text-transform: initial;
                padding-bottom: 15px;
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-34;
            color: #142954;
            margin: 0;
            line-height: 1.5;
        }
        .right-side {
            width: 50%;
        }
    }
    .bloc.left {
        .left-side {
            padding-right: 50px;
        }
    }
    .bloc.right {
        .right-side {
            padding-top: 50px;
            padding-left: 35px;
        }
    }
}

#section-burst {
    padding: 50px 2%;
    display: flex;
    a.burst {
        width: 33.3333%;
        background: #142954;
        padding: 50px;
        transition: 0.3s;
        .logo {
            padding-bottom: 50px;
            .img-responsive {
                margin: auto;
            }
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-white;
            padding-bottom: 35px;
            text-align: center;
        }
        &:hover {
            background: #d99529;
            transition: 0.3s;
        }
    }
    a.burst.middle {
        margin: 0 35px;
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-text-image {
    display: flex;
    background: #142954;
    padding: 25px 5%;
    .left-side {
        width: 50%;
        margin-right: 5%;
        padding-top: 25px;
        h3 {
            font-weight: 600;
            font-size: $font-size-40;
            color: $color-white;
            padding-bottom: 50px;
            line-height: 1.5;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-40;
            color: $color-white;
            line-height: 1.5;
        }
    }
}

#section-services {
    padding: 50px 0;
    .bloc {
        display: flex;
        .title {
            background: #142954;
            padding: 10px 20px;
            margin-bottom: 25px;
            h3 {
                font-weight: 600;
                font-size: $font-size-40;
                color: $color-white;
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: #142954;
            margin: 0;
            line-height: 1.5;
            padding: 0 20px;
        }
        p.middle {
            padding: 25px 20px;
        }
    }
    .bloc.left{
        .left-side {
            width: 40%;
        }
        .right-side {
            width: 60%;
        }
    } 
    .bloc.right { 
        .left-side {
            width: 60%;
        }
        .right-side {
            width: 40%;
        }
    } 
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#section-icon-title {
    padding: 0 2% 50px 2%;
    .icon {
        .img-responsive {
            margin: auto;
            margin-bottom: 25px;
        }
    }
    h3 {
        font-weight: bold;
        font-size: $font-size-50;
        color: #142954;
        text-transform: initial;
        width: 85%;
        margin: auto;
        text-align: center;
    }
}

#section-img-table {
    display: flex;
    position: relative;
    padding: 0 2% 50px 2%;
    .left-side {
        width: 100%;
        margin-right: 25px;
    }
    .right-side {
        width: 50%;
        padding-top: 25px;
        h4 {
            font-weight: bold;
            font-size: $font-size-26;
            color: #142954;
            text-transform: uppercase;
            line-height: 1.3;
        }
        .tableau {
            position: absolute;
            left: 53%;
            margin-top: 25px;
            width: 60%;
            table {
                width: 75%;
                border: 2px solid #142954;
                tr.color {
                    background: #dcddde;
                }
                tr {
                    background: #fff;
                }
                th {
                    background: #c8dff3;
                    padding: 15px 25px;
                    border: 1px solid #142954;
                    text-align: center;
                    font-weight: normal;
                    font-size: $font-size-30;
                    color: #142954;
                }
                td {
                    padding: 10px 25px;
                    text-align: center;
                    border: 1px solid #142954;
                    font-weight: normal;
                    font-size: $font-size-30;
                    color: #142954;
                }
            }
        }
    }
}

#text {
    padding: 25px 0 50px 0;
    text-align: center;
    p {
        font-weight: normal;
        font-size: $font-size-26;
        color: #142954;
        margin: 0;
        line-height: 1.3;
    }
}

#section-text-img {
    display: flex;
    padding: 0 2% 75px 2%;
    .left-side {
        width: 45%;
        .title {
            background: #142954;
            padding: 10px 20px;
            margin-bottom: 25px;
            h3 {
                font-weight: 600;
                font-size: $font-size-40;
                color: $color-white;
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-30;
            color: #142954;
            margin: 0;
            line-height: 1.5;
            padding: 25px 20px;
        }
        .tableau {
            position: absolute;
            left: 5%;
            margin-top: 25px;
            width: 60%;
            table {
                width: 75%;
                border: 2px solid #142954;
                tr.color {
                    background: #dcddde;
                }
                tr {
                    background: #fff;
                }
                th {
                    background: #c8dff3;
                    padding: 15px 25px;
                    border: 1px solid #142954;
                    text-align: center;
                    font-weight: normal;
                    font-size: $font-size-30;
                    color: #142954;
                }
                td {
                    padding: 10px 25px;
                    text-align: center;
                    border: 1px solid #142954;
                    font-weight: normal;
                    font-size: $font-size-30;
                    color: #142954;
                }
            }
        }
    }
    .right-side {
        width: 55%;
    }
}

#section-img-bloc {
    padding: 100px 2% 100px 0;
    display: flex;
    position: relative;
    .left-side {
        width: 45%;
    }
    .right-side {
        width: 60%;
        position: absolute;
        right: 2%;
        top: 65px;
        .bloc {
            background: #142954;
            padding: 15px;
            margin-bottom: 35px;
            h3 {
                font-weight: 600;
                font-size: $font-size-40;
                color: $color-white;
                padding-bottom: 10px;
            }
            p {
                font-weight: normal;
                font-size: $font-size-30;
                color: $color-white;
                margin: 0;
                line-height: 1.5;
            }
        }
        .bloc.first {
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

#image {
    padding: 25px 2%;
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 50px 2%;
    .realisations {
        display: flex;
        flex-wrap: wrap;
        .projets {
            width: 30%;
            margin: 0 25px 75px 25px;
            .titre {
                h3 {
                    font-weight: bold;
                    font-size: $font-size-36;
                    color: #142954;
                    padding-bottom: 10px;
                }
            }
            .description {
                background: #142954;
                padding: 15px;
                p {
                    font-weight: normal;
                    font-size: $font-size-28;
                    color: $color-white;
                    margin: 0;
                    line-height: 1.15;
                }
            }
        }
    }
}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#section-soumission {
    display: flex;
    align-items: center;
    padding: 100px 5%;
    .left-side {
        margin-right: 50px;
    }
    .right-side {
        text-align: center;
        margin: auto;
        .bloc {
            h3 {
                font-weight: bold;
                font-size: $font-size-50;
                color: #142954;
                line-height: 1.5;
            }
            h4 {
                font-weight: 600;
                font-size: $font-size-36;
                color: #142954;
                line-height: 1.5;
                text-transform: uppercase;
            }
        }
    }
}

#section-formulaire {
    background: #142954;
    padding: 50px 5%;
    margin: 0 2% 50px 2%;
    h3 {
        font-weight: 600;
        font-size: $font-size-36;
        color: $color-white;
        padding-bottom: 50px;
    }
    .col-xs-12.col-sm-6 {
        padding: 0 50px 0 0;
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-background {
    position: relative;
    padding: 0 5% 50px 5%;
    .text {
        p {
            font-weight: 600;
            font-size: $font-size-34;
            color: #142954;
            text-align: center;
            margin: 0;
            line-height: 1.5;
        }
    }
}

#title {
    text-align: center;
    padding: 50px 2%;
    h3 {
        font-weight: bold;
        font-size: $font-size-40;
        color: #142954;
    }
}

section.emplois-liste {
    padding: 25px 4% 50px 4%;
    .container-fluid.row {
        display: flex;
        .item-poste.emploi {
            border: 5px solid #d99529;
            padding: 0 25px;
            width: 50%;
            margin: 0 50px 50px 0;
            .date {
                padding-bottom: 50px;
                p {
                    font-weight: normal;
                    font-size: $font-size-30;
                    color: #142954;
                    margin: 0;
                    text-align: right;
                }
            }
            .title-box {
                h4 {
                    font-weight: 500;
                    font-size: $font-size-40;
                    color: #142954;
                    text-transform: uppercase;
                    padding-bottom: 50px;
                }
            }
            .content-full {
                p {
                    font-weight: normal;
                    font-size: $font-size-26;
                    color: #142954;
                    margin: 0 0 50px 0;
                    line-height: 1.5;
                }
            }
            .btn-emploi {
                width: 30%;
                margin: 0 auto -23px auto;
                .btn {
                    font-weight: 600;
                }
            }
        }
    }
}

#image-emploi {
    padding: 25px 0 0 0;
    .img-responsive {
        margin: auto;
    }
}


#section-form {
    background: #d99529;
    padding: 50px 5%;
    h3 {
        font-weight: 600;
        font-size: $font-size-36;
        color: $color-white;
        padding-bottom: 50px;
    }
    .col-xs-12.col-sm-6 {
        padding: 0 50px 0 0;
    }
    .btn {
        background: #142954;
        color: $color-white;
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    background: #142954;
    padding: 50px 5%;
    display: flex;
    align-items: center;
    .left-side {
        width: 50%;
        .icon {
            margin-bottom: 35px;
        }
        .info {
            h3 {
                font-weight: 600;
                font-size: $font-size-40;
                color: $color-white;
                text-transform: initial;
                line-height: 1.3;
            }
        }
    }
    .right-side {
        width: 50%;
    }
}

#section-heures {
    display: flex;
    align-items: center;
    padding: 0 0 0 5%;
    .left-side {
        width: 68%;
        p {
            font-weight: bold;
            font-size: $font-size-40;
            color: #142954;
            margin: 0;
            line-height: 1.5;
        }
        .jours-heures {
            display: flex;
            .jour {
                width: 35%;
            }
            p {
                line-height: 1.3;
            }
            .heure {
                p {
                    font-weight: normal !important;
                }
            } 
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1765px) {

#section-soumission .right-side .bloc h4 br {
    display: none;
}
}

@media screen and (max-width: 1725px) {

#section-images-text .bloc p {
    font-size: 1.4rem;
}
#section-text-image .left-side h3, #section-text-image .left-side h4 {
    font-size: 1.5rem;
}
}

@media screen and (max-width: 1700px) {

.slideshow-wrap .caption {
    top: 25%;
    left: 21%;
}
#section-text {
    padding: 50px 2%;
}  
#section-img-text .bloc.text p {
    font-size: 1.5rem;
}
#section-galerie h4 {
    padding-left: 3%;
    padding-bottom: 10px;
}
#section-heures .left-side .jours-heures .jour {
    width: 45%;
}
}

@media screen and (max-width: 1645px) {

#section-text-img .left-side .tableau {
    width: 68%;
}
#section-text-img {
    padding: 0 2% 165px 2%;
}
#section-img-bloc {
    padding: 100px 2% 200px 0;
}
#section-img-bloc .right-side .bloc p {
    font-size: 1.4rem;
}
}

@media screen and (max-width: 1600px) {

nav.module-menu .menu-navbar .upperpart .wrapper.left .logo {
    padding-left: 4%;
}  
.slideshow-wrap .caption {
    display: none;
}
#section-title-img .left-side h2 br {
    display: none;
}
#section-title-img .left-side {
    padding-top: 5%;
}
#section-galerie {
    padding: 25px 5% 75px 5%;
    background-size: cover;
}
#section-galerie .galerie .image {
    width: 33.3333%;
}
#section-galerie .galerie .image .img-responsive {
    width: 100%;
}
#section-galerie h4 {
    padding-left: 0%;
    padding-bottom: 10px;
}
#section-services .bloc p {
    font-size: 1.3rem;
}
#section-img-text .bloc.text p {
    font-size: 1.4rem;
}
}

@media screen and (max-width: 1560px) {

#section-realisations .realisations {
    justify-content: center;
}
#section-realisations .realisations .projets {
    width: 47%;
    margin: 0 35px 75px 0;
}
}

@media screen and (max-width: 1550px) {
   
#section-img-text .bloc.text p {
    font-size: 1.3rem;
}
#section-burst a.burst h3 {
    font-size: 1.3rem;
}
#section-images-text .bloc p {
    font-size: 1.3rem;
}
#section-services .bloc.right .right-side, #section-services .bloc.left .left-side {
    width: 50%;
}
#section-img-bloc .left-side {
    width: 55%;
}
#section-text-img {
    margin-bottom: 75px;
}
#section-img-text {
    align-items: center;
}
}

@media screen and (max-width: 1445px) {

nav.module-menu .menu-navbar .lowerpart .main-menu li.item {
    padding: 0px 25px;
}
#section-text-image .left-side h3, #section-text-image .left-side h4 {
    font-size: 1.35rem;
}
#section-services .bloc p {
    font-size: 1.15rem;
}
#section-text-img .left-side .tableau {
    width: 75%;
}
#section-img-table .right-side .tableau {
    left: 45%;
    width: 70%;
}
#section-img-table {
    margin-bottom: 50px;
}
#section-heures .left-side .jours-heures .jour {
    width: 52%;
}
#section-heures .left-side p {
    font-size: 1.75rem;
}
#section-title-img .left-side h2 {
    font-size: 3.8rem;
}
#section-img-text .bloc.text h3 {
    font-size: 2.2rem;
}
#section-img-text .bloc.text p {
    font-size: 1.2rem;
}
}

@media screen and (max-width: 1400px) {

#section-text h3 {
    font-size: 2.2rem;
}
#section-text p {
    font-size: 1.5rem;
}
#section-img-text .bloc.text h4 {
    font-size: 1.8rem;
}
#section-img-text .bloc.text p {
    font-size: 1.15rem;
}
#section-img-bloc .left-side {
    width: 60%;
}
#section-img-bloc {
    padding: 100px 2% 150px 0;
}
#section-heures .left-side p {
    font-size: 1.65rem;
}
}

@media screen and (max-width: $size-md-max) {
   
#section-galerie .galerie .image .img-responsive {
    height: 285px;
}
a#section-link2 .text p, #section-galerie h4 {
    font-size: 1.8rem;
}
#section-images-text .bloc p {
    font-size: 1.15rem;
}
#section-burst a.burst h3 {
    font-size: 1.2rem;
}
#section-burst a.burst {
    padding: 50px 25px;
}
#section-icon-title h3 {
    width: 100%;
}
#section-img-table {
    margin-bottom: 75px;
}
#section-img-bloc .left-side {
    width: 65%;
}
form .btn-files .icon {
    width: 15%;
    margin-left: -93px;
}
form .btn-files.mt8 {
    margin-top: 2rem !important;
}
#section-title-img .left-side h2 {
    font-size: 3.5rem;
}
}

@media screen and (max-width: 1130px) {

nav.module-menu .menu-toggle .word {
    font-size: 1.2rem;
}
nav.module-menu .menu-navbar .lowerpart .main-menu.right ul li.lang {
    display: none;
}
}

@media screen and (max-width: 1024px) {

#section-title-img .left-side h2 {
    font-size: 3rem;
}
#section-img-text .bloc.text {
    padding: 25px;
}
#section-img-text .bloc.text h3 {
    font-size: 2.2rem;
}
#section-img-text .bloc.text h4 {
    font-size: 1.5rem;
}
#section-galerie .galerie .image .img-responsive {
    height: 235px;
}
#section-images-text .bloc .left-side h3 {
    font-size: 1.8rem;
}
#section-images-text .bloc p {
    font-size: 1rem;
}
#section-burst a.burst .logo {
    width: 50%;
    margin: auto;
}
#section-burst a.burst h3 {
    font-size: 1rem;
    padding-bottom: 25px;
}
#section-text-image {
    flex-direction: column;
}
#section-text-image .left-side {
    width: 100%;
    margin-right: 0;
    padding-top: 25px;
}
#section-text-image .left-side h3, #section-text-image .left-side h4 {
    font-size: 1.5rem;
    padding-bottom: 25px;
}
#section-services .bloc {
    flex-direction: column;
}
#section-services .bloc.right .right-side, #section-services .bloc.left .left-side {
    width: 65%;
    margin: auto;
    padding-top: 35px;
}
#section-services .bloc.right .right-side .img-responsive, #section-services .bloc.left .left-side .img-responsive {
    margin: auto;
}
#section-services .bloc.left .right-side {
    width: 100%;
}
#section-services .bloc.right .left-side {
    width: 100%;
}
#section-img-table {
    margin-bottom: 0;
    flex-direction: column;
}
#section-img-table .left-side {
    width: 100%;
    margin-right: 0;
}
#section-img-table .right-side .tableau {
    position: initial;
}
#section-img-table .right-side {
    width: 100%;
    padding-top: 25px;
}
#section-img-table .right-side .tableau {
    position: initial;
    margin: auto;
    width: 100%;
}
#section-img-table .right-side .tableau table {
    margin: auto;
}
#section-img-table .right-side h4 br {
    display: none;
}
#section-img-table .right-side h4 {
    text-align: center;
    padding-bottom: 15px;
}
#text {
    padding: 25px 2%;
}
#section-text-img {
    margin-bottom: 0;
    flex-direction: column;
}
#section-text-img .left-side {
    width: 100%;
    margin-bottom: 50px;
}
#section-text-img .left-side .tableau {
    width: 100%;
    position: initial;
    display: flex;
    justify-content: center;
}
#section-text-img .right-side {
    width: 75%;
    margin: auto;
}
#section-text-img {
    padding: 50px 2%;
}
#section-img-bloc {
    padding: 50px 2%;
    flex-direction: column-reverse;
}
#section-img-bloc .left-side {
    width: 75%;
    margin: auto;
    margin-bottom: 35px;
}
#section-img-bloc .right-side {
    width: 75%;
    margin: auto;
    position: initial;
}
#section-img-bloc .right-side .bloc {
    margin-bottom: 15px;
}
#section-coordonnees {
    flex-direction: column;
}
#section-coordonnees .left-side {
    width: 100%;
    margin-bottom: 35px;
}
#section-coordonnees .right-side {
    width: 100%;
}
#section-coordonnees .right-side div#map-canvas {
    height: 450px !important;
}
#section-heures .left-side {
    width: 85%;
}
#section-realisations .realisations .projets {
    width: 45%;
    margin: 0 25px 75px 0;
}
#section-soumission .right-side .bloc h3 {
    font-size: 1.8rem;
}
#section-soumission .right-side .bloc h3 br {
    display: none;
}
#section-soumission .right-side .bloc h4 {
    font-size: 1.3rem;
}
#section-img-text .bloc.text h3 {
    font-size: 1.8rem;
}
}

@media screen and (max-width: $size-sm-max) {

#section-title-img .left-side {
    width: 90%;
}
#section-title-img .left-side h2 br {
    display: none;
}
#section-img-text .bloc {
    width: 100%;
}
#section-img-text .bloc.text p {
    font-size: 1.5rem;
}
#section-img-text .bloc.text h3 {
    font-size: 2.2rem;
}
#section-galerie .galerie .image .img-responsive {
    height: 175px;
}
#section-images-text .bloc {
    flex-direction: column;
}
#section-images-text .bloc .left-side {
    width: 100%;
    margin-bottom: 25px;
}
#section-images-text .bloc .right-side {
    width: 100%;
}
#section-images-text .bloc.left .left-side {
    padding-right: 0;
}
#section-images-text .bloc.right {
    flex-direction: column-reverse;
}
#section-images-text .bloc.right .right-side {
    padding-top: 50px;
    padding-left: 0;
    padding-bottom: 25px;
}
#section-images-text .bloc p {
    font-size: 1.3rem;
}
#section-burst {
    flex-direction: column;
}
#section-burst a.burst {
    width: 50%;
    padding: 30px;
    margin: auto;
}
#section-services .bloc.right .right-side, #section-services .bloc.left .left-side {
    width: 100%;
}
#section-burst a.burst.middle {
    margin: 35px auto;
}
#section-burst a.burst h3 {
    font-size: 1.3rem;
}
#section-text-img .left-side .tableau table, #section-text-img .right-side, #section-img-bloc .left-side, #section-img-bloc .right-side {
    width: 85%;
}
#section-heures {
    flex-direction: column-reverse;
    padding-bottom: 50px;
}
#section-heures .left-side .jours-heures .jour {
    width: 30%;
}
#section-soumission .right-side .bloc h3 {
    font-size: 1.5rem;
}
.btn-files.pt6 {
    padding-top: 1rem;
}
section.emplois-liste .container-fluid.row .item-poste.emploi .btn-emploi {
    width: 60%;
}
section.emplois-liste .container-fluid.row {
    flex-direction: column;
}
section.emplois-liste .container-fluid.row .item-poste.emploi {
    width: 100%;
    margin: 0 0 80px 0;
}
form .btn-files .icon {
    width: 10%;
    margin-right: 25px;
    margin-left: -37px;
}
#section-heures {
    padding: 0;
}
#section-heures .right-side {
    width: 85%;
    margin-bottom: 35px;
}   
}

@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-navbar .lowerpart {
    margin-top: -15px;
} 
nav.module-menu .menu-toggle .word {
    font-size: 1.1rem;
}
main {
    margin-top: 120px;
}
#section-title-img {
    flex-direction: column;
    padding-top: 20px;
}
#section-title-img .left-side h2 {
    text-align: center;
}
#section-images {
    flex-direction: column;
}
#section-images .image {
    width: 100%;
    padding-bottom: 10px;
}
#section-images .image.ml3.mr3 {
    margin: 0;
}
#section-img-text .bloc.text p {
    font-size: 1.3rem;
    text-align: left;
}
#section-galerie .galerie .image {
    width: 50%;
}
a#section-link2 .text p {
    font-size: 1.5rem;
}
#background {
    min-height: 150px;
    background-size: cover;
}
#section-link h3 {
    font-size: 3.25rem;
}
#section-burst a.burst {
    width: 85%;
}
#section-burst a.burst h3 {
    font-size: 1.5rem;
}
#section-img-table .right-side .tableau table th, #section-img-table .right-side .tableau table td, #section-text-img .left-side .tableau table th, #section-text-img .left-side .tableau table td {
    font-size: 1.25rem;
}
#section-text-img .left-side .tableau table, #section-text-img .right-side, #section-img-bloc .left-side, #section-img-bloc .right-side {
    width: 100%;
}
#section-img-bloc .right-side .bloc p br {
    display: none;
}
#section-heures {
    padding: 0 0 50px 0;
}
#section-heures .left-side {
    width: 100%;
    padding: 0 5%;
}
#section-title-img .left-side h2 {
    font-size: 3.5rem;
    padding-bottom: 35px;
}
#section-heures .left-side .jours-heures .jour {
    width: 40%;
}
#section-realisations .realisations .projets {
    width: 100%;
    margin: 0 0 50px 0;
}
#section-soumission {
    flex-direction: column;
}
#section-soumission .left-side {
    margin-right: 0;
    margin-bottom: 35px;
}
#section-soumission .right-side {
    text-align: center;
    margin-bottom: 50px;
}
#section-formulaire h3 {
    text-align: center;
}
#section-formulaire .col-xs-12.col-sm-6, #section-form .col-xs-12.col-sm-6 {
    padding: 0 5px;
}
#section-formulaire .col-xs-12.col-sm-6 .form-group.pt1, #section-formulaire .col-xs-12.col-sm-6 .btn-files.pt5 {
    padding-top: 0;
}
#section-background .text p {
    font-size: 1.35rem;
}
#section-background .text {
    top: 49%;
}
section.emplois-liste .container-fluid.row .item-poste.emploi .btn-emploi {
    margin: 0 auto -18px auto;
}
#section-background {
    margin-top: -50px;
}
#section-soumission {
    padding: 100px 5% 50px 5%;
}
#section-heures .right-side {
    width: 100%;
}
#section-img-text .bloc.text {
    padding: 25px 2%;
}
}

@media screen and (max-width: 360px) {

#section-text h3 {
    font-size: 2rem;
    text-align: center;
}
#section-img-text .bloc.text {
    padding: 15px;
}
#section-galerie .galerie .image .img-responsive {
    height: 135px;
}
a#section-link2 .text p {
    font-size: 1.25rem;
}
#section-burst a.burst {
    width: 100%;
}
#section-img-table .right-side .tableau table th, #section-img-table .right-side .tableau table td, #section-text-img .left-side .tableau table th, #section-text-img .left-side .tableau table td {
    font-size: 0.9rem;
}
#section-soumission .right-side .bloc h3 {
    font-size: 1.3rem;
}
#section-soumission .right-side .bloc h4 {
    font-size: 1.2rem;
}
#section-background .text p {
    font-size: 1.2rem;
}
#section-title-img .left-side h2 {
    font-size: 3rem;
}
}